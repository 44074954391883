import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

import * as S from './style'

const FinancieSeuImovelUS = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.SectionWrapper className='bg-grayscale--100'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 order-md-last text-center'>
            <ImageWebp
              arrayNumbers={[ 312, 336, 376, 456 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/credito-imobiliario-dobra05/image.webp'
              altDescription='Um homem feliz sorrindo'
            />
          </div>
          <div className='col-12 mt-4 col-md-6 col-xl-5 mt-mt-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 fw-600'>
              Financiamento Imobiliário nos Estados Unidos
            </h2>
            <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-grayscale--500 my-3'>
              Financie seu imóvel no exterior de forma simplificada.
            </p>
            <a
              href='https://inter.co/pra-voce/financiamento-imobiliario/financiamento-eua/'
              target='_blank'
              rel='noreferrer'
              className='btn btn--lg bg-orange--base text-none text-white rounded-2 fs-14 lh-16 fw-700 mt-2'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  element_action: 'click button',
                  section_name: 'Financiamento Imobiliário nos Estados Unidos',
                  element_name: 'Conheça mais',
                  redirect_url: 'https://inter.co/pra-voce/financiamento-imobiliario/financiamento-eua/',
                })
              }}
            >
              Conheça mais
            </a>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default FinancieSeuImovelUS
