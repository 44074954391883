import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const FinanciamentoImobiliario = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://inter.co/pra-voce/financiamento-imobiliario/residencial/'

  return (
    <section className='bg-white'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 text-center'>
            <ImageWebp
              arrayNumbers={[ 299.55, 349.95, 391.61, 474.95 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/he-dobra04/image.webp'
              altDescription='Um homem feliz sorrindo'
            />
          </div>
          <div className='col-12 mt-4 col-md-6 col-xl-5 mt-md-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 fw-600'>
              Financiamento Imobiliário
            </h2>
            <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-grayscale--500 mt-3 mb-4 mb-d-0'>
              Sua casa dos sonhos, sem burocracias e a um clique de você
            </p>
            <a
              href={url}
              target='_blank'
              rel='noreferrer'
              className='btn btn--lg bg-orange--base text-none text-white rounded-2 fs-14 lh-16 fw-700 mt-2'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  element_action: 'click button',
                  section_name: 'Financiamento Imobiliário',
                  element_name: 'Conheça mais',
                  redirect_url: url,
                })
              }}
            >
              Conheça mais
            </a>
          </div>
        </div>
      </div>
    </section>
    )
}

export default FinanciamentoImobiliario
