import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

const backgroundMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/credito-imobiliario/image.webp'
const backgroundTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/credito-imobiliario-768/image.webp'
const backgroundDesktopLG = 'https://central-imagens.bancointer.com.br/images-without-small-versions/credito-imobliario-1024/image.webp'
const backgroundDesktopXL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/credito-imobiliario-1440/image.webp'

export const ConstrucasaSection = styled.section`
  background-color: ${orange.extra};
  padding: 40px 0;
  background-image: url(${backgroundMobile});
  background-position: top 40px center;
  background-repeat: no-repeat;
  min-height: 560px;

  @media ${device.tablet} {
    padding: 56px 0;
    background-color: ${orange.extra};
    background-image: url(${backgroundTablet});
    min-height: 338px;
    background-position: right -30px center;
  }
  @media ${device.desktopLG} {
    padding: 64px 0;
    background-color: ${orange.extra};
    background-image: url(${backgroundDesktopLG});
    min-height: 552px;
    background-position: right center;
  }
  @media ${device.desktopXL} {
    padding: 96px 0;
    background-color: ${orange.extra};
    background-image: url(${backgroundDesktopXL});
    min-height: 586px;
    background-position: right -115px center;
  }

  .styled-button {
    color: ${white};
    width: 100%;
    padding: 0 24px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${orange.extra}
  }
`
