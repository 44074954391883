import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const SectionWrapper = styled.section`
  background-color: ${grayscale[100]};
  a {
    max-width: 100%;
    width: 100%;
  }
`
