import React from 'react'
import Icon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { ConstrucasaSection } from './style'

const Construcasa = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ConstrucasaSection className='bg-orange d-flex align-items-end align-items-md-center' id='simulador'>
      <div className='container'>
        <div className='row justify-content-between align-items-end'>
          <div className='col-12 col-md-6 col-lg-5 col-xl-4'>
            <h2 className='fs-32 lh-40 fs-md-48 lh-md-60 text-white text-left pt-4 pt-md-0 mb-4 mb-md-3'>
              Construcasa
            </h2>
            <p className='fs-16 lh-20 text-white mb-4 pb-2'>
              Financie até 60% da sua obra com segurança e tranquilidade
            </p>
            <div className='d-block'>
              <a
                href='https://inter.co/pra-voce/financiamento-imobiliario/construcasa/'
                target='_blank'
                rel='noreferrer'
                className='styled-button border border-white rounded-2 fs-14 lh-17 fw-600 text-none d-flex justify-content-between'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    element_action: 'click button',
                    element_name: 'Conheça mais',
                    section_name: 'Construcasa',
                    redirect_url: 'https://inter.co/pra-voce/financiamento-imobiliario/construcasa/',
                  })
                }}
              >
                <span>
                  Conheça mais
                </span>
                <Icon size='MD' icon='arrow-right' color='#FFF' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </ConstrucasaSection>
  )
}

export default Construcasa
