import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const SectionWrapper = styled.section`
  background-color: ${grayscale[100]};

  @media ${device.tablet} {
    min-height: 415px;
  }
  @media ${device.desktopLG} {
    min-height: 500px;
  }
  @media ${device.desktopXL} {
    min-height: 648px;
  }
  
  a {
    max-width: 100%;
    width: 100%;
  }
`
