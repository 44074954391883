import React from 'react'
import * as S from './style'

const Header = () => {
  return (
    <S.SectionWrapper>
      <div className='container pt-3 pt-md-0'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-orange--extra fw-600'>
              Crédito Imobiliário é no Inter
            </h1>
            <p className='fs-18 fs-md-14 fs-lg-16 fs-xl-18 lh-22 lh-md-16 lh-lg-20 lh-xl-22 text-grayscale--500 pr-xl-5'>
              Conheça as nossas soluções de crédito e tire os seus planos do papel.
            </p>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default Header
