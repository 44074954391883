import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'
import * as S from './style'

const EmprestimoComGarantia = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.SectionWrapper className='bg-grayscale--100 align-items-md-center d-flex'>
      <div className='container py-5 py-md-0'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 text-center'>
            <ImageWebp
              arrayNumbers={[ 312, 341.577, 382.241, 463.568 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/credito-imobiliario-dobra2/image.webp'
              altDescription='Uma mulher feliz sorrindo'
              className='mb-4 mb-md-0'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 mt-4 mt-md-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 fw-600'>
              Empréstimo com Garantia de Imóvel
            </h2>
            <p className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-grayscale--500 my-3'>
              Crédito a partir de 70mil reais para usar como quiser!
            </p>
            <a
              href='https://inter.co/pra-voce/emprestimos/home-equity/'
              target='_blank'
              rel='noreferrer'
              className='btn btn--lg bg-orange--base text-none text-white rounded-2 mt-2'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  section_name: 'Empréstimo com Garantia de Imóvel',
                  element_name: 'Conheça mais',
                  redirect_url: 'https://inter.co/pra-voce/emprestimos/home-equity/',
                })
              }}
            >
              Conheça mais
            </a>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default EmprestimoComGarantia
