import styled from 'styled-components'
import { orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

import HeaderMD from '../../assets/image/lp-credito-header-md.png'
import HeaderLG from '../../assets/image/lp-credito-header-lg.png'
import HeaderXL from '../../assets/image/lp-credito-header-xl.png'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: flex-end;

  @media ${device.tablet} {
    height: 294px;
    background-image: url(${HeaderMD});
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
  }

  @media ${device.desktopLG} {
    height: 438px;
    background-image: url(${HeaderLG});
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.desktopXL} {
    height: 615px;
    background-image: url(${HeaderXL});
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export const ButtonHeader = styled.a`
  border: 1px solid ${orange.extra};
  padding-top: 15px;
`
